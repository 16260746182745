// ** React Imports
import { forwardRef } from 'react'

// ** Third Party Components
import Proptypes from 'prop-types'
import classnames from 'classnames'

// ** Reactstrap Imports
import { Badge } from 'reactstrap'

const Avatar = forwardRef((props, ref) => {
    // ** Props
    const {
        img,
        size,
        icon,
        color,
        status,
        badgeUp,
        content,
        tag: Tag,
        initials,
        imgWidth,
        className,
        badgeText,
        imgHeight,
        badgeColor,
        imgClassName,
        contentStyles,
        ...rest
    } = props

    // ** Function to extract initials from content
    const getInitials = str => {
        if (str) {
            const results = []
            const wordArray = str.split(' ')
            wordArray.forEach(e => {
                if (e[0] && e[0].match(/[a-z]/i)) {
                    if (e[0] === e[0].toUpperCase()) {
                        results.push(e[0])
                    }
                }

            })
            return results.join('')
        }
    }

    return (
        <Tag
            data-tag="allowRowEvents"
            className={classnames('avatar', {
                [className]: className,
                [`bg-${color}`]: color,
                [`avatar-${size}`]: size
            })}
            ref={ref}
            {...rest}
        >
            {img === false || img === undefined ? (
                <span
                    data-tag="allowRowEvents"
                    className={classnames('avatar-content', {
                        'position-relative': badgeUp
                    })}
                    style={contentStyles}
                >
                    {initials ? getInitials(content) : content}

                    {icon ? icon : null}
                    {badgeUp ? (
                        <Badge color={badgeColor ? badgeColor : 'primary'} className='badge-sm badge-up' pill>
                            {badgeText ? badgeText : '0'}
                        </Badge>
                    ) : null}
                </span>
            ) : (
                <img
                    data-tag="allowRowEvents"
                    className={classnames({
                        [imgClassName]: imgClassName
                    })}
                    src={img}
                    alt='avatarImg'
                    height={imgHeight && !size ? imgHeight : 32}
                    width={imgWidth && !size ? imgWidth : 32}
                />
            )}
            {status ? (
                <span
                    data-tag="allowRowEvents"
                    className={classnames({
                        [`avatar-status-${status}`]: status,
                        [`avatar-status-${size}`]: size
                    })}
                ></span>
            ) : null}
        </Tag>
    )
})

export default Avatar

// ** PropTypes
Avatar.propTypes = {
    icon: Proptypes.node,
    src: Proptypes.string,
    badgeUp: Proptypes.bool,
    content: Proptypes.string,
    badgeText: Proptypes.string,
    className: Proptypes.string,
    imgClassName: Proptypes.string,
    contentStyles: Proptypes.object,
    size: Proptypes.oneOf(['sm', 'lg', 'xl']),
    tag: Proptypes.oneOfType([Proptypes.func, Proptypes.string]),
    status: Proptypes.oneOf(['online', 'offline', 'away', 'busy']),
    imgHeight: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
    imgWidth: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
    badgeColor: Proptypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'info',
        'warning',
        'dark',
        'light-primary',
        'light-secondary',
        'light-success',
        'light-danger',
        'light-info',
        'light-warning',
        'light-dark'
    ]),
    color: Proptypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'info',
        'warning',
        'dark',
        'light-primary',
        'light-secondary',
        'light-success',
        'light-danger',
        'light-info',
        'light-warning',
        'light-dark'
    ])
}

// ** Default Props
Avatar.defaultProps = {
    tag: 'div'
}
