import { toast } from "react-toastify"

const createToastShowerTooManyRequest = () => {
    let count = 0
    let toastId = null // This will hold our toast ID

    const showToast = () => {
      count += 1
      const message = `Te veel verzoeken, probeer het later opnieuw. Aantal mislukte verzoeken: ${count}`

      if (toastId === null || !toast.isActive(toastId)) {
        // The toast isn't active, so let's create a new one
        toastId = toast.error(message, {
          // This toast will not close until done so manually
          autoClose: true
        })
      } else {
        toast.update(toastId, {
          render: message
        })
      }
    }

    return showToast
  }
const showToastTooMany = createToastShowerTooManyRequest()

const createToastShowerSlowDown = () => {
    const toastId = "rateLimitToast" // Custom unique toastId
    const message = `Het lijkt er op dat u veel verzoeken doet, tijdelijk zullen de verzoeken vertraagd worden.`

    const showToast = (duration = 5000) => {
        console.log(duration)
        // Only show the toast if it's not already active
        if (!toast.isActive(toastId)) {
            toast.warning(message, {
                toastId,
                autoClose: duration
            })
        } else {
            toast.update(toastId, {
                render: message
              })
        }
    }

    return showToast
}

const showToastSlowDown = createToastShowerSlowDown()


export { showToastTooMany, showToastSlowDown}
