import axios from 'axios'
import { showToastSlowDown } from './TooManyToast'

// this is a rate limiter for axios build by chatgpt
const AxiosRequestHandler = () => {
    // Configuration for the rate limit
    const MAX_REQUESTS_PER_MINUTE = 60 // Max number of requests per minute
    const SOFT_LIMIT = 45 // After this many requests, start introducing a delay
    const ONE_MINUTE_MS = 60000 // Number of milliseconds in one minute

    let totalRequests = 0 // Counter for the total requests sent
    let firstRequestTime = Date.now() // Time when the first request was made


    // Helper function to calculate the delay based on the total requests made
    const calculateDelay = () => {
        const timeElapsed = Date.now() - firstRequestTime

        if (timeElapsed >= ONE_MINUTE_MS) {
            // Reset counters after a minute
            totalRequests = 0
            firstRequestTime = Date.now()
            return 0 // No delay, as we're in a new minute
        }

        // No delay if under the soft limit
        if (totalRequests < SOFT_LIMIT) {
            return 0
        }

        // If we are between SOFT_LIMIT and MAX_REQUESTS_PER_MINUTE, we start introducing a delay
        if (totalRequests >= SOFT_LIMIT && totalRequests < MAX_REQUESTS_PER_MINUTE) {
            // Calculate the delay proportional to the excess over the SOFT_LIMIT
            const ratio = (totalRequests - SOFT_LIMIT) / (MAX_REQUESTS_PER_MINUTE - SOFT_LIMIT)
            const delay = ratio * ONE_MINUTE_MS
            return delay
        }

        // If we've reached MAX_REQUESTS_PER_MINUTE, calculate the delay to not exceed the limit
        const delay = ONE_MINUTE_MS - timeElapsed
        return delay
    }

    axios.interceptors.request.use((config) => {
        // Increase the total requests counter
        totalRequests++

        // Calculate the necessary delay
        const delay = calculateDelay()
        if (delay !== 0) {
            showToastSlowDown(delay)
        }

        // Return a new promise that resolves after the delay, allowing the request to proceed
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(config) // Resolve the promise and proceed with the request
            }, delay)
        })
    }, (error) => {
        // Handle request error
        return Promise.reject(error)
    })
}

export default AxiosRequestHandler
