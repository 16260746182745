import { createSlice } from '@reduxjs/toolkit'
import { toast, Slide } from 'react-toastify'
import { Coffee } from 'react-feather'
import Avatar from '@components/avatar'

const ToastContent = ({ name }) => (
    <div className='toastify-header'>
        <div className='title-wrapper'>
            <Avatar size='sm' color='success' icon={<Coffee size={12} />} />
            <h6 className='toast-title fw-bold'>Welkom, {name}</h6>
        </div>
    </div>
)

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: undefined
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload.userData
            state['token'] = action.payload.token
            localStorage.setItem('token', action.payload.token)
            toast.dismiss()
            toast.success(
                <ToastContent name={state.userData.full_name} />,
                { icon: false, transition: Slide, autoClose: 2000 }
            )
        },
        handleLogout: state => {
            state.userData = null
            state['token'] = null
            localStorage.removeItem('token')
        },
        setUserData: (state, action) => {
            state.userData = action.payload.userData
        }
    }
})

export const { handleLogin, handleLogout, setUserData } = authSlice.actions

export default authSlice.reducer
