import axios from "axios"
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'
import { useNavigate } from "react-router-dom"
import { showToastTooMany } from "./TooManyToast"

import AxiosRequestHandler from "./request"

const baseURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8000/"
axios.defaults.baseURL = baseURL
axios.defaults.withCredentials = true


//interceptors
const AxiosHandler = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    AxiosRequestHandler()

    axios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            if (
                error.response &&
                error.response.status === 429
            ) {

                showToastTooMany()
            }

            if (
                error.response &&
                error.response.status === 401 &&
                error.config &&
                !error.config.__isRetryRequest
            ) {
                localStorage.removeItem('token')
                navigate('/login')
            } else if (error.response && error.response.status === 429) {
                console.log("Too Many Requests")
                dispatch(handleLogout())
            } else if (
                error.response &&
                error.response.status === 500 &&
                (
                    error.response.data.message === "The token has been blacklisted" ||
                    error.response.data.message === "Token has expired and can no longer be refreshed" ||
                    error.response.data.message === "Token Signature could not be verified." ||
                    error.response.data.message === "Token not provided"
                )
            ) {
                dispatch(handleLogout())
            } else if (error.response && error.response.status === 500) {
                console.log(error.response.data)
            }

            return Promise.reject(error)
        }
    )
    return null
}

export default AxiosHandler
