import { Ability } from '@casl/ability'
import { store } from '@store/store'

let currentUser
const existingAbility = new Ability()

store.subscribe(() => {
    const prevUser = currentUser
    currentUser = store.getState().auth.userData
    if (currentUser && prevUser !== currentUser) {
        existingAbility.update([currentUser.permissions])
    }
})

export default existingAbility
